export default class KeyboardKey {
    constructor(key) {
        this.key = key;
    }
    static isDown(key) {
        return !!KeyboardKey.pressedKeys[key];
    }
    static isUp(key) {
        return !KeyboardKey.pressedKeys[key];
    }
    isDown() {
        return KeyboardKey.isDown(this.key);
    }
    isUp() {
        return KeyboardKey.isUp(this.key);
    }
}
KeyboardKey.pressedKeys = {};
(() => {
    document.addEventListener('keydown', (e) => {
        const key = e.key;
        KeyboardKey.pressedKeys[key] = true;
    });
    document.addEventListener('keyup', (e) => {
        const key = e.key;
        KeyboardKey.pressedKeys[key] = false;
    });
})();

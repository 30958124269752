import * as CANNON from 'cannon-es';
export default class PhysicsWorld extends CANNON.World {
    constructor(options) {
        super();
        this.gravity.set(...options.gravity);
        this.allowSleep = true;
    }
    tick(elapsedTime, deltaElapsedTime) {
        this.step(1 / 60, deltaElapsedTime, 3);
    }
}

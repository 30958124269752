import DefaultOrbitCamera from '../../src/engine/cameras/DefaultOrbitCamera';
import Engine from '../../src/engine/Engine';
import PhysicsTrailScene from './PhysicsTrailScene';
import '../../src/style.css';
const canvas = document.querySelector('canvas.webgl');
const engine = new Engine(canvas, PhysicsTrailScene)
    .enablePhysics({ gravity: [0, -9.82, 0] })
    .enableGUI()
    .enableStats();
engine.setCamera(new DefaultOrbitCamera(engine));
engine.start();

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as THREE from 'three';
import * as CANNON from 'cannon-es';
import GameElement from './GameElement';
import { threeToCannon } from 'three-to-cannon';
import { bodyToMesh } from '../utils/bodyToMesh';
const defaultOptions = {
    updateDirection: 'bodyToMesh',
    updatePosition: true,
    updateRotation: true,
    renderMesh: true,
};
export default class DefaultPhysicsElement extends GameElement {
    constructor(mesh, bodyOptions = {}, options = {}) {
        super();
        this.position = new THREE.Vector3();
        this.rotation = new THREE.Euler();
        if (mesh instanceof THREE.Object3D) {
            this.mesh = mesh;
            this.continueConstruction(bodyOptions, options);
        }
    }
    continueConstruction(bodyOptions = {}, options = {}) {
        this.options = Object.assign(Object.assign({}, defaultOptions), options);
        this.handleBody(bodyOptions);
        this.setPosition(this.mesh, this.body);
        this.setQuaternion(this.mesh, this.body);
        this.position = this.positionProxy();
        this.rotation = this.quaternionProxy();
    }
    onEnterScene() {
        const world = this.engine.getPhysicsWorld();
        if (world)
            world.addBody(this.body);
    }
    onExitScene() {
        const world = this.engine.getPhysicsWorld();
        if (world)
            world.removeBody(this.body);
    }
    tick() {
        this.updateElements();
        this.updateWireframe();
    }
    updateElements() {
        const { from, to } = this.getUpdateDirectionElements();
        if (this.options.updatePosition)
            this.setPosition(from, to);
        if (this.options.updateRotation)
            this.setQuaternion(from, to);
    }
    updateWireframe() {
        if (!this.meshWireframe)
            return;
        this.setPosition(this.body, this.meshWireframe);
        this.setQuaternion(this.body, this.meshWireframe);
    }
    getUpdateDirectionElements() {
        if (this.options.updateDirection === 'bodyToMesh')
            return { from: this.body, to: this.mesh };
        return { from: this.mesh, to: this.body };
    }
    getMesh() {
        return this.mesh;
    }
    getBody() {
        return this.body;
    }
    render() {
        return [this.options.renderMesh && this.mesh, this.meshWireframe];
    }
    handleBody(_a) {
        var { shape, positionOffset, quaternionOffset } = _a, bodyOptions = __rest(_a, ["shape", "positionOffset", "quaternionOffset"]);
        this.body = new CANNON.Body(bodyOptions);
        if (shape) {
            this.body.addShape(shape, positionOffset, quaternionOffset);
        }
        else {
            const result = threeToCannon(this.mesh);
            this.body.addShape(result.shape, result.offset, result.orientation);
        }
        if (this.options.wireframe) {
            this.meshWireframe = bodyToMesh(this.body, new THREE.MeshBasicMaterial({ wireframe: true }));
        }
    }
    setPosition(from, to) {
        to.position.set(from.position.x, from.position.y, from.position.z);
    }
    setQuaternion(from, to) {
        to.quaternion.set(from.quaternion.x, from.quaternion.y, from.quaternion.z, from.quaternion.w);
    }
    positionProxy() {
        return new Proxy(this.mesh.position, {
            set: (target, key, value) => {
                target[key] = value;
                this.setPosition(this.mesh, this.body);
                return true;
            },
            get: (target, key) => {
                if (!['x', 'y', 'z'].includes(key))
                    throw new Error('Somente é possível acessar as propriedades X, Y, Z.');
                return target[key];
            },
        });
    }
    quaternionProxy() {
        return new Proxy(this.mesh.rotation, {
            set: (target, key, value) => {
                target[key] = value;
                this.setQuaternion(this.mesh, this.body);
                return true;
            },
            get: (target, key) => {
                if (!['x', 'y', 'z'].includes(key))
                    throw new Error('Somente é possível acessar as propriedades X, Y, Z.');
                return target[key];
            },
        });
    }
}

import GameElement from "./elements/GameElement";
import { Object3D } from "three";
export default class SceneManipulator {
    constructor(father) {
        this.castShadow = null;
        this.receiveShadow = null;
        this.controlledObjects = [];
        this.lastApplyedCastShadow = this.castShadow;
        this.lastApplyedReceiveShadow = this.receiveShadow;
        this.parent = father;
    }
    applyRenderReturn(ret) {
        ret = this.normalizeRenderReturn(ret);
        this.computeAndApplyDiff(ret);
    }
    normalizeRenderReturn(ret) {
        if (!ret)
            return [];
        if (ret.constructor.name == 'Array') {
            ret = ret.flat(1);
            return ret;
        }
        else {
            return [ret];
        }
    }
    computeAndApplyDiff(newRet) {
        const addToScene = newRet.filter(e => !this.controlledObjects.includes(e));
        const removeFromScene = this.controlledObjects.filter(e => !newRet.includes(e));
        const threeObjectsToRemove = removeFromScene.filter(e => e instanceof Object3D);
        const threeObjectsToAdd = addToScene.filter(e => e instanceof Object3D);
        const gameElementsToRemove = removeFromScene.filter(e => e instanceof GameElement);
        const gameElementsToAdd = addToScene.filter(e => e instanceof GameElement);
        let anythingChanged = false;
        const scene = this.engine.getScene();
        if (threeObjectsToRemove.length) {
            scene.remove.apply(scene, threeObjectsToRemove);
        }
        if (threeObjectsToAdd.length) {
            scene.add.apply(scene, threeObjectsToAdd);
        }
        gameElementsToAdd.forEach((ge) => {
            ge.setEngine(this.engine);
            ge.setParent(this.parent);
            ge.wrapOnEnterScene();
            ge.requestRender();
        });
        gameElementsToRemove.forEach((ge) => ge.wrapOnExitScene());
        anythingChanged = !!(threeObjectsToRemove.length | threeObjectsToAdd.length | gameElementsToAdd.length | gameElementsToRemove.length);
        this.controlledObjects = newRet;
        if (anythingChanged)
            this.applyShadowsConfig();
    }
    applyShadowsConfig() {
        this.controlledObjects.filter(o => o instanceof Object3D).forEach((o) => {
            o.traverse(e => {
                if (!e.isLight) {
                    e.receiveShadow = this.receiveShadow != undefined ? this.receiveShadow : e.receiveShadow;
                }
                if (!e.isAmbientLight) {
                    e.castShadow = this.castShadow != undefined ? this.castShadow : e.castShadow;
                }
            });
        });
        this.controlledObjects.filter(o => o instanceof GameElement).forEach((o) => {
            o.setCastShadow(this.castShadow);
            o.setReceiveShadow(this.castShadow);
        });
        this.lastApplyedCastShadow = this.castShadow;
        this.lastApplyedReceiveShadow = this.receiveShadow;
    }
    clearScene() {
        this.computeAndApplyDiff([]);
    }
    setCastShadow(v) {
        this.castShadow = v;
        this.parent.requestRender();
    }
    setReceiveShadow(v) {
        this.receiveShadow = v;
        this.parent.requestRender();
    }
    getCastShadow() {
        return this.castShadow;
    }
    getReceiveShadow() {
        return this.receiveShadow;
    }
    setEngine(engine) {
        this.engine = engine;
    }
}

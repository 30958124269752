import { VRButton } from 'three/examples/jsm/webxr/VRButton.js';
export default class WebXR {
    constructor(engine) {
        this.onSessionStartListeners = [];
        this.renderer = engine.getRenderer();
        this.renderer.xr.enabled = true;
        this.renderer.xr.addEventListener('sessionstart', () => this.onSessionStartListeners.forEach(listener => listener()));
        document.body.appendChild(VRButton.createButton(this.renderer));
    }
    getGrip() {
        return this.renderer.xr.getController(0);
    }
    onSessionStart(listener) {
        this.onSessionStartListeners.push(listener);
    }
}

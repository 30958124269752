import KeyboardKey from '../controls/KeyboardKey';
import SceneManipulator from '../SceneManipulator';
import { v4 as uuidv4 } from 'uuid';
export default class GameElement {
    constructor() {
        this.renderTimeout = null;
        this.uuid = uuidv4();
        this.sceneManipulator = new SceneManipulator(this);
        this.child = this;
        this.elementName = this.child.constructor.name;
        this.isInScene = false;
        this.beforeExitSceneCallback = null;
    }
    requestRender() {
        clearTimeout(this.renderTimeout);
        this.renderTimeout = setTimeout(this.wrapRender.bind(this), 15);
    }
    wrapRender() {
        if (!this.child.render)
            return;
        if (!this.isInScene)
            return;
        const ret = this.child.render();
        this.sceneManipulator.applyRenderReturn(ret);
    }
    wrapTick(elapsedTime) {
        const childDecorators = GameElement.decoratorsInGameElements.get(this.constructor);
        if (childDecorators) {
            //console.log(childDecorators) //this.constructor, GameElement.decoratorsInGameElements)
            if (childDecorators.whileDown) {
                childDecorators.whileDown.forEach((member, k) => {
                    if (KeyboardKey.isDown(k)) {
                        this.child[member](elapsedTime);
                    }
                });
            }
            if (childDecorators.whileUp) {
                childDecorators.whileUp.forEach((member, k) => {
                    if (KeyboardKey.isUp(k)) {
                        this.child[member](elapsedTime);
                    }
                });
            }
        }
        if (this.child.tick) {
            this.child.tick(elapsedTime);
        }
    }
    wrapOnEnterScene() {
        if (this.child.onEnterScene) {
            this.beforeExitSceneCallback = this.child.onEnterScene();
        }
        if (this.child.state && this.child.state.constructor.name != 'Proxy') {
            this.child.state = new Proxy(this.child.state || {}, {
                set: (target, key, value) => {
                    target[key] = value;
                    this.isInScene && this.requestRender();
                    return true;
                }
            });
        }
        this.isInScene = true;
        this.engine.addTickListener(this);
    }
    setCastShadow(v) {
        //console.log(this.elementName, 'setCastShadow', v)
        this.sceneManipulator.setCastShadow(v);
    }
    setReceiveShadow(v) {
        //console.log(this.elementName, 'setReceiveShadow', v)
        this.sceneManipulator.setReceiveShadow(v);
    }
    getCastShadow() {
        return this.sceneManipulator.getCastShadow();
    }
    getReceiveShadow() {
        return this.sceneManipulator.getReceiveShadow();
    }
    wrapOnExitScene() {
        if (this.beforeExitSceneCallback) {
            this.beforeExitSceneCallback();
            this.beforeExitSceneCallback = null;
        }
        if (this.child.onExitScene) {
            this.child.onExitScene();
        }
        this.engine.removeTickListener(this);
        this.sceneManipulator.clearScene();
        this.isInScene = false;
    }
    getElementName() {
        return this.elementName;
    }
    setEngine(engine) {
        this.engine = engine;
        this.sceneManipulator.setEngine(engine);
    }
    getEngine() {
        return this.engine;
    }
    getParent() {
        return this.parent;
    }
    setParent(parent) {
        this.parent = parent;
    }
    static registerWhileKeySubclassFunction(subclass, key, memberName, type) {
        let decorators = GameElement.decoratorsInGameElements.get(subclass);
        if (!decorators) {
            decorators = {};
            GameElement.decoratorsInGameElements.set(subclass, decorators);
        }
        if (!decorators[type]) {
            decorators[type] = new Map();
        }
        decorators[type].set(key, memberName);
    }
}
GameElement.decoratorsInGameElements = new Map();

import * as THREE from 'three';
import Stats from 'three/examples/jsm/libs/stats.module';
import PhysicsWorld from './PhysicsWorld';
import WebXR from './WebXR';
import GUI from 'lil-gui';
export default class Engine {
    constructor(target, rootElementConstructor) {
        this.tickListeners = [];
        this.rootElement = null;
        this.lastElapsedTime = 0;
        this.info = emptyAppInfo(target);
        this.scene = new THREE.Scene();
        this.rootElementConstructor = rootElementConstructor;
        this.renderer = new THREE.WebGLRenderer({
            canvas: this.info.target,
            antialias: true,
        });
        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        this.renderer.setSize(this.info.sizes.width, this.info.sizes.height);
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        this.clock = new THREE.Clock();
    }
    enableWebxr() {
        this.webxr = new WebXR(this);
        return this;
    }
    enablePhysics(options) {
        this.physicsWorld = new PhysicsWorld(options);
        return this;
    }
    enableStats(mode = 0) {
        this.stats = Stats();
        this.stats.setMode(mode);
        document.body.appendChild(this.stats.dom);
        return this;
    }
    enableGUI() {
        this.gui = new GUI();
        return this;
    }
    setCamera(camera) {
        this.camera = camera;
        return this;
    }
    getScene() {
        return this.scene;
    }
    getRenderer() {
        return this.renderer;
    }
    getGui() {
        return this.gui;
    }
    getCamera() {
        return this.camera;
    }
    getPhysicsWorld() {
        return this.physicsWorld;
    }
    getWebxr() {
        return this.webxr;
    }
    start() {
        this.rootElement = new this.rootElementConstructor();
        this.rootElement.setEngine(this);
        this.rootElement.wrapOnEnterScene();
        this.rootElement.requestRender();
        this.renderer.setAnimationLoop(() => this.executeTick());
        return this;
    }
    executeTick() {
        var _a, _b;
        const elapsedTime = this.clock.getElapsedTime();
        const deltaElapsedTime = elapsedTime - this.lastElapsedTime;
        Engine.currentAdjustMultiplier = deltaElapsedTime / (1 / 60);
        this.lastElapsedTime = elapsedTime;
        if (this.camera && this.camera.tick) {
            this.camera.tick(elapsedTime);
        }
        (_a = this.physicsWorld) === null || _a === void 0 ? void 0 : _a.tick(elapsedTime, deltaElapsedTime);
        this.tickListeners.forEach(e => e.wrapTick(elapsedTime));
        if (this.camera) {
            this.renderer.render(this.scene, this.camera);
        }
        (_b = this.stats) === null || _b === void 0 ? void 0 : _b.update();
    }
    addTickListener(element) {
        this.tickListeners.push(element);
    }
    removeTickListener(element) {
        this.tickListeners.splice(this.tickListeners.indexOf(element), 1);
    }
}
const emptyAppInfo = (target) => ({
    target,
    sizes: {
        width: window.innerWidth,
        height: window.innerHeight,
    },
});
export function adjustToTickTime(scalar) {
    return scalar * Engine.currentAdjustMultiplier;
}
